import React from 'react'
import './footer.css'
import { BsFillPiggyBankFill } from "react-icons/bs";
import { FaShippingFast, FaWallet } from "react-icons/fa";
import { FaHeadphonesSimple } from "react-icons/fa6";

const Footer = () => {
  return (
    <>
    <div className='footer'>
        <div className='container'>
            <div className='left-box'>
            <img src='image/IMG_2266.JPG' alt=''></img>
                <div className='box'>
                    <div className='icon_box'>
                        <BsFillPiggyBankFill />
                    </div>
                    <div className='detail'>
                        <h3>Great Saving</h3>
                        <p>an object or collection of objects wrapped</p>
                    </div>
                </div>
                    <div className='box'>
                    <div className='icon_box'>
                        <FaShippingFast />
                    </div>
                    <div className='detail'>
                        <h3>fast delivery</h3>
                        <p>an object or collection of objects wrapped</p>
                    </div>
                </div>
                    <div className='box'>
                    <div className='icon_box'>
                        <FaHeadphonesSimple />
                    </div>
                    <div className='detail'>
                        <h3>24X7 support</h3>
                        <p>an object or collection of objects wrapped</p>
                </div>
                </div>
                    <div className='box'>
                    <div className='icon_box'>
                        <FaWallet />
                    </div>
                    <div className='detail'>
                        <h3>money back</h3>
                        <p>an object or collection of objects wrapped</p>
                </div>
                </div>
                </div>
                <div className='right_box'>
                    <div className='header'>
                        <img src='image/IMG_2266.JPG' alt=''></img>
                        <p>My app has been on Ionic v1 for several years now and I have never seen this error befornd I have </p>
                    </div>
                    <div className='bottom'>
                        <div className='box'>
                            <h3>Your Account</h3>
                            <ul>
                                <li>About us</li>
                                <li>Account</li>
                                <li>Payment</li>
                                <li>Sales</li>
                            </ul>
                        </div>
                        <div className='box'>
                            <h3>products</h3>
                            <ul>
                                <li>Delivery</li>
                                <li>Track order</li>
                                <li>New product</li>
                                <li>Old product</li>
                            </ul>
                        </div>
                        <div className='box'>
                            <h3>contact us</h3>
                            <ul>
                                <li>your address here broooooo</li>
                                <li>your phone number</li>
                                <li>your gmail</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      
    </>
  )
}

export default Footer