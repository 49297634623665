import React, { useEffect, useState } from 'react'
import './home.css'
import {Link} from 'react-router-dom'
import Homeproduct from './home_product'
import { FaEye, FaHeart, FaTiktok, FaWhatsapp } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { AiFillInstagram } from "react-icons/ai";
import { IoCartOutline } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";
const Home = ({addtocart}) => {
    //product category
    const[newProduct, setNewProduct] = useState([])
    const[featuredProduct, setFeaturedProduct] = useState([])
    const[topProduct, setTopProduct] = useState([])
    const[specialProduct, setSpecialProduct] = useState([])
    //trending product
    const[trendingProduct, setTrendingProduct] = useState(Homeproduct)
    //filter of trending product
    const filtercate =(x) =>
    {
        const filterproduct = Homeproduct.filter((curElm)=>
        {
            return curElm.type === x
        })
        setTrendingProduct(filterproduct)
    }
    //end
    // all trending product
    const allTrendingProduct =() => 
    {
        setTrendingProduct(Homeproduct)
    }

    //product type
    useEffect(()=>
    {
        productcategory()
    })
    const productcategory = () =>
    {
        //special product
        const specialcategory = Homeproduct.filter((x) => {
            return x.type ==='special'
        })
        setSpecialProduct(specialcategory)
        //New Product
        const newcategory = Homeproduct.filter((x) => {
            return x.type ==='top'
        })
        setNewProduct(newcategory)

        //Featured Product
        const featuredcategory = Homeproduct.filter((x) => {
            return x.type ==='jean'
        })
        setFeaturedProduct(featuredcategory)

        //top product
        const topcategory = Homeproduct.filter((x) => {
            return x.type ==='fullfit'
        })
        setTopProduct(topcategory)
    }

    //toggle product detail
    const[showDetail, setShowDetail] = useState(false)
    //detail page data
    const[detail, setDetail] = useState([])
    //showing detail box
    const detailpage =(product) =>
    {
        const detaildata = ([{product}])
        const productdetail = detaildata[0]['product']
        // console.log(productdetail)
        setDetail(productdetail)
        setShowDetail(true)
    }
    console.log(detail)
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const closedetail =() =>
    {
        setShowDetail(false)  
    }
    // let currentImageIndex = 0;
    const images = [detail.image, detail.image2, detail.image3, detail.image4, detail.image5, detail.image6].filter(Boolean) // Add your image array here
    console.log('Images length:', images.length);
    
    function setImage(image) {
        const mainImageElement = document.getElementById('mainImage');
    if (mainImageElement) {
        mainImageElement.src = image;
    }
    }
    
    function changeImage(direction) {
        setCurrentImageIndex(prevIndex => {
            let newIndex = prevIndex + direction;
    
            if (newIndex < 0) {
                return images.length - 1; // Loop to last image
            } else if (newIndex >= images.length) {
                return 0; // Loop to first image
            }
            
            return newIndex; // Return the new index
        });
    }
    useEffect(() => {
        if (images.length > 0) {
            setImage(images[currentImageIndex]);
        }
    }, [currentImageIndex, images]);

    return (
        <>
        {
            showDetail ?
            <>
        <div className='product_detail'>
            <button className='close_btn' onClick={closedetail} onTouchTap={closedetail} style={{ cursor: 'pointer' }}><IoMdClose /></button>
            <div className='container'>
                <div className='img_box'>
                    <img id="mainImage" src={detail.image} alt='' />
                </div>
                <div className='info'>
                    {/* <h4># {detail.cat}</h4> */}
                    <h2>{detail.Name}</h2>
                    <p>{detail.p}</p>
                    <h3>${(detail.price/1700).toFixed(0)}</h3>
                    <button onClick={() => addtocart (detail)}>Add To Cart</button>
                </div>
            </div>
            {images.length > 1 && (
    <div className="image_scroll">
        <button className="prev" onClick={() => changeImage(-1)}>&#10094;</button>
        <button className="next" onClick={() => changeImage(1)}>&#10095;</button>
        <div className="thumbnail_container">
            {detail.image && <img className="thumbnail" alt='' src={detail.image} onClick={() => setImage(detail.image)} />}
            {detail.image2 && <img className="thumbnail" alt='' src={detail.image2} onClick={() => setImage(detail.image2)} />}
            {detail.image3 && <img className="thumbnail" alt='' src={detail.image3} onClick={() => setImage(detail.image3)} />}
            {detail.image4 && <img className="thumbnail" alt='' src={detail.image4} onClick={() => setImage(detail.image4)} />}
        </div>
    </div>
)}
        </div>
            </>
            : null
        }
    <div className='home'>
        <div className='top_banner'>
            <div className='content'>
                {/* <h3>silver aluminium</h3> */}
                <h2>Unique Styles</h2>
                <p>Make your first order</p>
                <Link to='/shop' className='link'>Shop Now</Link>
            </div>
        </div>
        <div className='trending'>
            <div className='container'>
                <div className='left_box'>
                    <div className='header'>
                        <div className='heading'>
                            {/* <h2 onClick={() => allTrendingProduct ()}>trending product</h2> */}
                            <h2 onClick={() => filtercate('special')}>Holiday Collection</h2>
                        </div>
                        <div className='cate'>
                            <h3 onClick={() => filtercate('new')}> </h3>
                            <h3 onClick={() => filtercate('featured')}> </h3>
                            <h3 onClick={() => filtercate('top')}> </h3>
                        </div>
                    </div>
                    <div className='products'>
                        <div className='container'>
                            {
                              specialProduct.map((curElm) =>
                              {
                                return(
                                    <>
                                    <div className='box'>
                                        <div className='img_box'>
                                            <img src={curElm.image} alt=''></img>
                                            <div className='icon'>
                                                <div onClick={() =>detailpage (curElm)} className='icon_box'>
                                                    <FaEye />
                                                </div>
                                                <div className='icon_box'>
                                                    <FaHeart />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='info'>
                                            <h3>{curElm.Name}</h3>
                                            <p>${(curElm.price / 1700).toFixed(0)}</p>
                                            <button className='btn' onClick={() => addtocart(curElm)}>Add To Cart</button>
                                        </div>
                                    </div>
                                    </>
                                ) 
                              })  
                            }
                        </div>
                        <Link to='/shop'><button>Show More</button></Link>
                    </div>
                </div>
                <div className='right_box'>
                    <div className='right_container'>
                        <div className='testimonial'>
                            <div className='head'>
                                <h3>our testimonial</h3>
                            </div>
                            <div className='detail'>
                                <div className='img_box'>
                                    <img src='image/T1.avif' alt='testimonial'></img>
                                </div>
                                <div className='info'>
                                    <h3>Our EMail</h3>
                                    <h4>Style With Ease</h4>
                                    <p> My app has been on Ionic v1 for several years now and I have never seen this error before. As v1 is now archived and no longer supported.</p>
                                </div>
                            </div>
                        </div>
                        <div className='newsletter'>
                            <div className='head'>
                                <h3>newsletter</h3>
                            </div>
                            <div className='form'>
                                <p>join our mailing list</p>
                                <input type='email' placeholder='E-Mail' autoComplete='off'></input>
                                <button>Subscribe</button>
                                <div className='icon_box'>
                                <div className='icon'>
                                <AiFillInstagram  />
                                </div>
                                <div className='icon'>
                                <FaXTwitter />
                                </div>
                                <div className='icon'>
                                <FaWhatsapp />
                                </div>
                                <div className='icon'>
                                <FaTiktok />
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='banners'>
            <div className='container'>
                <div className='left_box'>
                    <div className='box'>
                        <img  className='img1' src='image/Multi-Banner-1.avif' alt='banner'></img>
                    </div> 
                    <div className='box'>
                        <img className='img2' src='image/Multi-Banner-2.avif' alt='banner'></img>
                    </div>
                </div>
                <div className='right_box'>
                    <div className='top'>
                        <img  className='img3'src='image/Multi-Banner-3.webp' alt=''></img>
                        <img className='img4' src='image/Multi-Banner-4.avif' alt=''></img>
                    </div>
                    <div className='bottom'>
                        <img  className='img5' src='image/Multi-Banner-5.webp' alt=''></img>
                    </div>
                </div>
            </div>
        </div>
        <div className='product_type'>
            <div className='container'>
                <div className='box'>
                <div className='header'>
                    <h2>New Product</h2>
                    </div>
                    {
                          newProduct.map((curElm) =>
                            {
                                return(
                                <>
                             <div className='productbox'>
                                <div className='img-box'>
                                    <img src={curElm.image} alt=''></img>
                                </div>
                                <div className='detail'>
                                    <h3>{curElm.Name}</h3>
                                    <p>${(curElm.price/1700).toFixed(0)}</p>
                                    <div className='icon'>
                                        <button onClick={() =>detailpage (curElm)}><FaEye /></button>
                                        <button><FaHeart /></button>
                                        <button><IoCartOutline onClick={() => addtocart(curElm)}/></button>
                                    </div>
                                </div>
                             </div>
                                </>
                                )


                            })
                          }
                      
                            
                </div>
                <div className='box'>
                <div className='header'>
                    <h2>Featured Product</h2>
                    </div>
                    {
                          featuredProduct.map((curElm) =>
                            {
                                return(
                                <>
                             <div className='productbox'>
                                <div className='img-box'>
                                    <img src={curElm.image} alt=''></img>
                                </div>
                                <div className='detail'>
                                    <h3>{curElm.Name}</h3>
                                    <p>${(curElm.price/1700).toFixed(0)}</p>
                                    <div className='icon'>
                                        <button onClick={() =>detailpage (curElm)}><FaEye /></button>
                                        <button><FaHeart /></button>
                                        <button><IoCartOutline onClick={() => addtocart(curElm)} /></button>
                                    </div>
                                </div>
                             </div>
                                </>
                                )


                            })
                          }
                      
                            
                </div>
                <div className='box'>
                <div className='header'>
                    <h2>Top Product</h2>
                    </div>
                    {
                          topProduct.map((curElm) =>
                            {
                                return(
                                <>
                             <div className='productbox'>
                                <div className='img-box'>
                                    <img src={curElm.image} alt=''></img>
                                </div>
                                <div className='detail'>
                                    <h3>{curElm.Name}</h3>
                                    <p>${(curElm.price/1700).toFixed(0)}</p>
                                    <div className='icon'>
                                        <button onClick={() =>detailpage (curElm)}><FaEye /></button>
                                        <button><FaHeart /></button>
                                        <button><IoCartOutline onClick={() => addtocart(curElm)}/></button>
                                    </div>
                                </div>
                             </div>
                                </>
                                )


                            })
                          }
                      
                            
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default Home