import React, { useState } from 'react';
import { MdLocalShipping } from 'react-icons/md';
import { IoMdSearch } from 'react-icons/io';
import { FiLogIn, FiUser } from "react-icons/fi";
import { useAuth0 } from "@auth0/auth0-react";
import { CiLogout } from "react-icons/ci";
import { Link } from "react-router-dom";
import './nav.css';
import { FaBarsStaggered } from "react-icons/fa6";
import { IoMdClose } from "react-icons/io";

const Nav = ({search, setSearch, searchproduct}) => {
  const { loginWithRedirect, logout, user, isAuthenticated } = useAuth0();
  const [openNav, setOpenNav] = useState(false)


  //handling open navbar
  const navopen =() =>
  {
    setOpenNav(true)
  }
  const closenav = () =>
  {
    setOpenNav(false)
  }
    // Function to handle closing the navbar when a link is clicked
    const handleLinkClick = () => {
      closenav();
    }
  return (
    <>
      <div className='header'>
        <div className='top_header'>
          <div className='icon'>
            <MdLocalShipping />
          </div>
          <div className='info'>
            <p>Free Shipping When Shopping up to $150</p>
          </div>
        </div>
        <div className='mid_header'>
          <div className='navicon'>
            {
              openNav ?
              <>
              <div className='closenav' onClick={closenav}>
              <IoMdClose />
              </div>
              </>
              :
              <>
              <div className='navopen' onClick={navopen}>
            <FaBarsStaggered />
            </div>
              </>
            }
          </div>
          <div className='logo'>
          <div className='log'><img src='image/IMG_2266.JPG' alt='logo'></img>
          <div className='letter'>Style With 
          <span> Ease</span>
          </div>
          </div>
          </div>
          <div className='search_box'>
            <input type='text'value={search} placeholder='search' onChange={(e) => setSearch(e.target.value)}></input>
            <button onClick={searchproduct}><IoMdSearch /></button>
          </div>
          {isAuthenticated ? (
            <div className='user'>
              <div className='icon'>
                <CiLogout />
              </div>
              <div className='btn'>
                <button onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>Logout</button>
              </div>
            </div>
          ) : (
            // login logo and txt
            <div className='user'> 
              <div className='icon'>
                <FiLogIn />
              </div>
              <div className='btn'>
                <button onClick={() => loginWithRedirect()}> </button>
              </div>
            </div>
          )}
        </div>
        <div className={`last_header ${openNav && "active"}`}>
        <div className='logo2'>
            <img src='image/logo.webp' alt='logo'></img>
          </div>
          <div className='user_profile'>
            {isAuthenticated ? (
              <>
                <div className='icon'>
                  <FiUser />
                </div>
                <div className='info'>
                  <h2>{user.name}</h2>
                  <p>{user.email}</p>
                </div>
              </>
            ) : (
              <>
                <div className='icon'>
                  <FiUser />
                </div>
                <div className='info'>
                  <p> </p>
                </div>
              </>
            )}
          </div>
          <nav className='nav'>
            <ul>
            <li onClick={handleLinkClick}><Link to='/' className='link'>Home</Link></li>
              <li onClick={handleLinkClick}><Link to='/shop' className='link'>Shop</Link></li>
              <li onClick={handleLinkClick}><Link to='/cart' className='link'>Cart</Link></li>
              {/* <li onClick={handleLinkClick}><Link to='/about' className='link'>About</Link></li> */}
              <li onClick={handleLinkClick}><Link to='/contact' className='link'>Contact</Link></li>
              <div className='user_profile2'>
                {isAuthenticated ? (
                  <>
                    <div className='icon2'>
                      <FiUser />
                    </div>
                    <div className='info2'>
                      <h2>{user.name}</h2>
                      <p>{user.email}</p>
                    </div>
                  </>
                ) : (
                  <>
                    <div className='icon2'>
                      <FiUser />
                    </div>
                    <div className='info2'>
                      <p>Please Login</p>
                    </div>
                  </>
                )}
              </div>
            </ul>
          </nav>
          <div className='offer'>
            <p>Flat 10% off on all Wears</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Nav;
