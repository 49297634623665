import React from 'react'
import './cart.css'
import { Link } from 'react-router-dom'
import { IoMdClose } from "react-icons/io";

const Cart = ({cart, setCart}) => {

    //increase quantity of cart product
     const incqty = (product) => 
     {
        const exist = cart.find((x) => 
        {
            return x.id === product.id
        })
        setCart(cart.map((curElm) =>
        {
            return curElm.id === product.id ? {...exist, qty: exist.qty +1} : curElm
        }))
     }
         //decrease quantity of cart product
         const decqty = (product) => 
            {
               const exist = cart.find((x) => 
               {
                   return x.id === product.id
               })
               setCart(cart.map((curElm) =>
               {
                   return curElm.id === product.id ? {...exist, qty: exist.qty -1} : curElm
               }))
            }

        //Removing cart product
        const removeproduct =(product) =>
        {
            const exist = cart.find((x) => 
            {
                return x.id === product.id
            })
            if(exist.qty > 0)
            {
                setCart(cart.filter((curElm) =>
                {
                    return curElm.id !== product.id
                })) 
            }
        }
        //Total Price
  const total = cart.reduce((price, item) => price + item.qty * (item.price/1700).toFixed(0), 0)
    // Function to send form data to WhatsApp
    const sendToWhatsApp = (data) => {
        const message = encodeURIComponent(
            `Order Details:\n` +
            cart.map(item => `${item.Name} ${item.type} - Quantity: ${item.qty} - Price: $${(item.price/1700).toFixed(0)}`).join('\n') +
            `\nTotal: $${(data.total/1700).toFixed(0)}`
        );

        const phoneNumber = '2349039679183'; // Replace with the recipient's phone number
        const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;
        
        // Open WhatsApp
        window.open(whatsappUrl, '_blank');
    };
  // Handle form submission
    const handleSubmit = (event) => {
            event.preventDefault();
            const formData = new FormData(event.target);
            const data = Object.fromEntries(formData.entries());
            data.total = cart.reduce((price, item) => price + item.qty * item.price, 0); // Calculate total
            console.log(data); // Log form data for debugging
            sendToWhatsApp(data); // Send data to WhatsApp
    }
    // Your existing functions...

    



  return (
    <>
    <div className='cart'>
        <h3>#cart</h3>
        {
            cart.length === 0 &&
            <>
            <div className='empty_cart'>
                <h2>Your Shopping Cart Is Empty</h2>
                <Link to='/shop'><button>Shop Now</button></Link>
            </div>
            </>
        }
        <form onSubmit={handleSubmit} className='container'>
            {
                cart.map((curElm) =>
                {
                    return(
                        <>
                        <div className='box'  key={curElm.id}>

                            <div className='img_box'>
                                <img src={curElm.image} alt=''></img>
                            </div>
                            <div className='detail'>
                                <div className='info'>
                                <h4>{curElm.cat}</h4>
                                <h3>{curElm.Name}</h3>
                                <p>Price: ${(curElm.price/1700).toFixed(0)}</p>
                                <p>Total : ${(curElm.price/1700).toFixed(0) * curElm.qty}</p>
                                </div>
                                <div className='quantity'>
                                    <button type="button" onClick={() => decqty (curElm)}>-</button>
                                    <input type='number' name={`qty-${curElm.id}`} value={curElm.qty}></input>
                                    <button  type="button" onClick={() => incqty (curElm)}>+</button>
                                </div>
                                <div className='icon'>
                                    <li onClick={() => removeproduct(curElm)}><IoMdClose /></li>
                                </div>
                            </div>
                        </div>
                        </>
                    )
                })
            }
        {/* </div> */}
        <div className='bottom'>
            {
                cart.length >0 &&
                <>
            <div className='Total'>
                <h4>Subtotal: ${total}</h4>
            </div>
            <button type='submit' className='checkout'>checkout</button>
                </>
            }
        </div>
        </form>
    </div>
    </>
  )
}

export default Cart
