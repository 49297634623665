import React, { useState } from 'react'
import './shop.css'
import {FaHeart, FaEye} from "react-icons/fa";
import { IoMdClose } from "react-icons/io";


const Shop = ({shop,Filter,allcatefilter, addtocart}) => {
    //toggle product detail
    const[showDetail, setShowDetail] = useState(false)
    //detail page data
    const[detail, setDetail] = useState([])
    //showing detail box
    const detailpage =(product) =>
    {
        const detaildata = ([{product}])
        const productdetail = detaildata[0]['product']
        // console.log(productdetail)
        setDetail(productdetail)
        setShowDetail(true)
    }
    console.log('showDetail:', showDetail);
    // console.log(detail)
    const closedetail =() =>
    {
        // console.log('Closedetail function called');
        setShowDetail(false)  
    }

    let currentImageIndex = 0;
const images = [detail.image, detail.image2, detail.image3, detail.image4].filter(Boolean) // Add your image array here
console.log('Images length:', images.length);

function setImage(image) {
    document.getElementById('mainImage').src = image;
}

function changeImage(direction) {
    currentImageIndex += direction;

    if (currentImageIndex < 0) {
        currentImageIndex = images.length - 1; // Loop to last image
    } else if (currentImageIndex >= images.length) {
        currentImageIndex = 0; // Loop to first image
    }

    setImage(images[currentImageIndex]);
}
  return (
    <>
    {
        showDetail ?
        <>
    <div className='product_detail'>
        <button className='close_btn' onClick={closedetail} onTouchTap={closedetail} style={{ cursor: 'pointer' }}><IoMdClose /></button>
        <div className='container'>
            <div className='img_box'>
                <img id="mainImage" src={detail.image} alt='' />
            </div>
            <div className='info'>
                {/* <h4># {detail.cat}</h4> */}
                <h2>{detail.Name}</h2>
                <p>{detail.p}</p>
                <h3>${(detail.price/1700).toFixed(0)}</h3>
                <button onClick={() => addtocart (detail)}>Add To Cart</button>
            </div>
        </div>
        
        {images.length > 1 && (
    <div className="image_scroll">
        <button className="prev" onClick={() => changeImage(-1)}>&#10094;</button>
        <button className="next" onClick={() => changeImage(1)}>&#10095;</button>
        <div className="thumbnail_container">
            {detail.image && <img className="thumbnail" alt='' src={detail.image} onClick={() => setImage(detail.image)} />}
            {detail.image2 && <img className="thumbnail" alt='' src={detail.image2} onClick={() => setImage(detail.image2)} />}
            {detail.image3 && <img className="thumbnail" alt='' src={detail.image3} onClick={() => setImage(detail.image3)} />}
            {detail.image4 && <img className="thumbnail" alt='' src={detail.image4} onClick={() => setImage(detail.image4)} />}
        </div>
    </div>
)}
    </div>
        </>
        : null
    }
    <div className='shop'>
        <h2># shop</h2>
        <p>Home . Shop</p>
        <div className='container'>
            <div className='left_box'>
                <div className='category'>
                    <div className='header'>
                        <h3>all categories</h3>
                    </div>
                    <div className='box'>
                        <ul>
                            <li onClick={() => allcatefilter ()} onTouchEnd={() => allcatefilter ()}># All</li>
                            <li onClick={() => Filter("shirt")} onTouchEnd={() => Filter("shirt")}># top</li>
                            <li onClick={() => Filter("jean")} onTouchEnd={() => Filter("jean")}># pants</li>
                            <li onClick={() => Filter("fullfit")} onTouchEnd={() => Filter("fullfit")}># full fit</li>
                            {/* <li onClick={() => Filter("shirt")} onTouchEnd={() => Filter("shirt")}># hoodies</li>
                            <li onClick={() => Filter("electronics")} onTouchEnd={() => Filter("jean")}># shoes</li>
                            <li onClick={() => Filter("headphone")} onTouchEnd={() => Filter("jean")}># thrift</li>
                            <li onClick={() => Filter("phone")} onTouchEnd={() => Filter("jean")}># bags</li> */}
                        </ul>
                    </div>
                </div>
                <div className='banner'>
                    <div className='img_box'>
                        <img src='image/shop_left.avif' alt=''></img>
                    </div>
                </div>
            </div>
            <div className='right-box'>
                <div className='banner'>
                    <div className='img_box'>
                        <img src='image/shop_top.webp' alt=''></img>
                    </div>
                </div>
                <div className='product_box'>
                    <h2>Shop Product</h2>
                    <div className='product_container'>
                        {
                            shop.map((curElm) =>{
                        <div className='box'></div>
                                return(
                                    <>
                        <div className='box'>
                            <div className='img_box'>
                                <img src={curElm.image} alt=''></img>
                                <div className='icon'>
                                <li> <FaHeart /></li> 
                                <li onClick={() =>detailpage (curElm)}> <FaEye /></li>
                                </div>
                            </div>
                            <div className='detail'>
                                <h3>{curElm.Name}</h3>
                                <p>${(curElm.price/1700).toFixed(0)}</p>
                                <button onClick={() => addtocart (curElm)}>Add To Cart</button>
                            </div>
                        </div>
                                    </>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default Shop